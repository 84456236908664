<template>
  <div class="container">
    <bread-crumb :navigationArray="navigationLink"/>
    <div v-if="getPage && getPage.content" v-html="getPage.content"></div>
  </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
  name: 'Page',
  created () {
    this.$store.dispatch('getPage', this.$route.params.id)
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.getPage ? (this.$store.getters.GET_LANG ? this.getPage.title : this.getPage.title_eng) : '...'
        }
      ]
    },
    getPage () {
      return this.$store.getters.getPage
    }
  },
  components: {
    BreadCrumb
  }
}
</script>

<style scoped lang="scss">

</style>
